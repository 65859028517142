import { themes } from "./theme";
export const ver = 'A6409';
export const verData = {
  "A6402": {
    title: "Galaxy Macau",
    moneyType: '฿',
    needBank: false,
    // url: 'https://a5888.vip/',
    url: 'https://venetianvn1.com/',
    navTheme: "#0d192c",
    theme: "#00bbfe",
    pagesBG: "#0d192c",
    areaCode: "66",
    lan: "en",
    loginBG: require("@tt/assets/login/loginBG.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USD", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    useNewUsername: true,
    // tabbar: {
    //   background: "#2a2b35",
    //   // selectedColor: "#0073e4",
    //   color: "#eee",
    //   type: "image",
    //   pages: [
    //     {
    //       text: 'newTab.home',
    //       pagePath: 'goods',
    //       component: () => import("@/view/goods"),
    //       iconPath: require("@tt/assets/tabbar/home.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/home_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'newTab.order'}`,
    //       pagePath: 'cart',
    //       component: () => import('@/view/cart'),
    //       iconPath: require("@tt/assets/tabbar/record.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/record_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'page.games'}`,
    //       pagePath: 'Merchants',
    //       component: () => import('@/view/new/merchants'),
    //       iconPath: require("@tt/assets/tabbar/game.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/game_active.png"),
    //       // isSpecial: true,
    //     },
    //     {
    //       text: `tab.activity`,
    //       pagePath: 'activity',
    //       component: () => import('@/view/new/activity'),
    //       iconPath: require("@tt/assets/tabbar/prize.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/prize_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'newTab.my'}`,
    //       pagePath: 'user',
    //       component: () => import('@/view/user'),
    //       iconPath: require("@tt/assets/tabbar/mine.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/mine_active.png"),
    //       isSpecial: false,
    //     },
    //   ]
    // }
  },
  'A6402_test': {
    title: "Galaxy Macau",
    moneyType: '฿',
    url: 'http://149.129.175.229:6401/',
    navTheme: "#0d192c",
    theme: "#00bbfe",
    pagesBG: "#0d192c",
    areaCode: "66",
    lan: "th",
    loginBG: require("@tt/assets/login/loginBG.png"),
    needBank: true,
    // tabbar: {
    //   background: "#2a2b35",
    //   // selectedColor: "#0073e4",
    //   color: "#eee",
    //   type: "image",
    //   pages: [
    //     {
    //       text: 'newTab.home',
    //       pagePath: 'goods',
    //       component: () => import("@/view/goods"),
    //       iconPath: require("@tt/assets/tabbar/home.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/home_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'newTab.order'}`,
    //       pagePath: 'cart',
    //       component: () => import('@/view/cart'),
    //       iconPath: require("@tt/assets/tabbar/record.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/record_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'page.games'}`,
    //       pagePath: 'Merchants',
    //       component: () => import('@/view/new/merchants'),
    //       iconPath: require("@tt/assets/tabbar/game.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/game_active.png"),
    //       // isSpecial: true,
    //     },
    //     {
    //       text: `tab.activity`,
    //       pagePath: 'activity',
    //       component: () => import('@/view/new/activity'),
    //       iconPath: require("@tt/assets/tabbar/prize.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/prize_active.png"),
    //       isSpecial: false,
    //     },
    //     {
    //       text: `${'newTab.my'}`,
    //       pagePath: 'user',
    //       component: () => import('@/view/user'),
    //       iconPath: require("@tt/assets/tabbar/mine.png"),
    //       selectedIconPath: require("@tt/assets/tabbar/mine_active.png"),
    //       isSpecial: false,
    //     },
    //   ]
    // }
  },
  "T7386": {
    title: 'jazp',
    moneyType: '₹',
    needBank: true,
    areaCode: 91,
    // useMoreArea: true,
    cusArea: [
      {
        "en": "United Arab Emirates",
        "zh": "阿拉伯联合酋长国",
        "code": "AE",
        "areaCode": "971"
      },
      {
        "en": "India",
        "zh": "印度",
        "code": "IN",
        "areaCode": "91"
      },
      {
        "en": "Saudi Arabia",
        "zh": "沙特阿拉伯",
        "code": "SA",
        "areaCode": "966"
      },
      {
        "en": "Kuwait",
        "zh": "科威特",
        "code": "KW",
        "areaCode": "965"
      }
    ],
    lan: 'en',
    needCode: true,
    url: 'https://jazpshop.vip/',
    // bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    useNewPatener: true,
    useNewCampanyImg: true,
    useNewInviteCode: true,
    // newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    // useNewWithdraw: true,
    defaultBank: "HDFC",
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    banks: [
      {
        value: "HDFC",
        text: "HDFC",
      },
    ],
  },
  "A6405": {
    title: "SunCity",
    moneyType: '฿',
    url: 'https://a1688888.cyou/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "en",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USD", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6405Test": {
    title: "SunCity",
    moneyType: '฿',
    url: 'http://149.129.175.229:6405/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "en",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USD", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6406": {
    title: "venetianvn",
    moneyType: 'USD',
    url: 'https://venetianvn1.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.a6406Light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
    //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "A6406_test": {
    title: "venetianvn",
    moneyType: 'USD',
    url: 'https://ga6408.gtzs.cc/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.a6406Light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
    //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "A6408": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://hi689.xyz/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6408_test": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://ga6408.gtzs.cc/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6408_test_zh": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://ga6408.gtzs.cc/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "zh",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6409": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://idn999.cc/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "idn",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6410": {
    title: "Ma Cao",
    moneyType: '$',
    url: 'https://xskt555.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6411": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://66688.cyou/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "idn",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6412": {
    title: "Marina Bay Sands",
    moneyType: 'Rp.',
    url: 'https://toto24.vip/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "idn",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [10000000, 900000000] },
      { symbol: "RM", abbr: "RM", interval: [10000000, 600000000] },
      { symbol: "$", abbr: "USDT", interval: [10000000, 1000000000] },
      { symbol: "S$", abbr: "SGD", interval: [10000000, 400000000] },
      { symbol: "Rp.", abbr: "RP", interval: [10000000, 900000000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6413": {
    title: "MARINA BAY SAND",
    moneyType: '$',
    url: 'https://casino06.vip/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6416": {
    title: "Marina Bay Sands",
    moneyType: '$',
    url: 'https://vn566888.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USDT", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USDT", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6406Test": {
    title: "venetianvn",
    moneyType: 'USD',
    url: 'http://149.129.175.229:6405/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.a6406Light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
      //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "A6451": {
    title: "Dream Vip",
    moneyType: '฿',
    url: 'https://ht515.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.light,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    randMoneyTypes: [
      { symbol: "฿", abbr: "THB", interval: [100, 300000] },
      { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
      { symbol: "$", abbr: "USD", interval: [100, 10000] },
      { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    ],
    rechargeSelectCurrency: ["VND", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true
  },
  "A6501": {
    // title: "venetianvn",
    title: "QUỸ UPS",
    moneyType: 'VND',
    url: 'https://tamlongviet7.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.heartLight,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
    //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "A6506": {
    title: "venetianvn",
    moneyType: 'VND',
    url: 'https://nhanai999.com/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    theme: "#FE6807",
    themes: themes.heartLight,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
    //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "A6501Test": {
    title: "venetianvn",
    moneyType: 'VND',
    url: 'https://a6501.a168888.cyou/',
    // navTheme: "#0d192c",
    // theme: "#00bbfe",
    // pagesBG: "#e4f0fa",
    themes: themes.heartLight,
    areaCode: "66",
    lan: "vi",
    loginBG: require("@tt/assets/login/loginBG_A6405.png"),
    // randMoneyTypes: [
    //   { symbol: "฿", abbr: "THB", interval: [100, 300000] },
    //   { symbol: "RM", abbr: "RM", interval: [1000, 200000] },
    //   { symbol: "$", abbr: "USD", interval: [100, 10000] },
    //   { symbol: "S$", abbr: "SGD", interval: [100, 100000] },
    // ],
    rechargeSelectCurrency: ["THB", "RM", "SGD", "USD", "GBP"],
    needBank: true,
    useNewUsername: true,
    notLoginToRegist: true
  },
  "T7201": {
    title: 'VIPMART',
    moneyType: 'đồng',
    needBank: true,
    isLinkGame: true,
    // areaCode: 1,
    appVersion: "101",
    lan: 'vi',
    lans: [
      // { name: "Português", value: "pt", icon: require("@tt/i18n/icon/pt.png"), areaCode: 55 },
      // { name: "English", value: "en", icon: require("@tt/i18n/icon/en.png"), areaCode: 1 },
      // { name: "Español", value: "es", icon: require("@tt/i18n/icon/es.png"), areaCode: 34 },
      { name: "Tiếng Việt", value: "vi", icon: require("@tt/i18n/icon/vi.png"), areaCode: 1 },
      // { name: "繁體中文", value: "tw", icon: require("@tt/i18n/icon/tw.png"), areaCode: 1 }
    ],
    rechargeList: [
      "20000",
      "500000",
      "1000000",
      "5000000",
      "10000000",
      "30000000"
    ],
    needCode: true,
    url: 'https://viplucky2.com/',
    // bankInput: true,
    vipHide: false,
    needFromid: true,
    // bankCardLimit: 11,
    banUserName: false,
    notLoginToRegist: true,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    // pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    // useNewWithdraw: true,
    // addCardForms: ["account", "bank", "card", "ifsc"],
    addCardForms: ["account", "bank", "card"],
    theme: "#ff6400",
    navBg: ["#f53d2c", "#ff6534"],
    navBGToBottom: true,
    noticeIconColor: "#000",
    loginSetLans: false,
    homeShowBalanceAtTop: true,
    homeShowLevels: true,
    hallPhonenumStart: 9,
    mineShowAboutBar: true,
    moneyFormatWithBrazil: true,
    hasGiftRate: true,
    banEditBank: true,
    mineShowWallet: true,
    useSignGift: true,
    hideNotice: true,
    registerHasRead: true,
    levelsReverse: true,
    banDefaultRead: true,
    nullPng: require("@tt/assets/Img/null/T7404.png"),
    messageNullPng: require("@tt/assets/Img/null/T7404_message.png"),
    addressNullPng: require("@tt/assets/Img/null/T7404_address.png"),
    nulltrade: require("@tt/assets/Img/null/T7404_1.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4",
    dateFormat: "dd/MM/yyyy",
    banks: [
      { value: "EMAIL", text: "EMAIL" },
      { value: "PHONE", text: "PHONE" },
      { value: "CPF", text: "CPF" },
      { value: "CNPJ", text: "CNPJ" },
      { value: "RANDOM", text: "RANDOM" },
    ]
  },
  "T7201Test": {
    title: 'VIPMART',
    moneyType: 'đồng',
    needBank: true,
    isLinkGame: true,
    // areaCode: 1,
    appVersion: "101",
    lan: 'vi',
    lans: [
      // { name: "Português", value: "pt", icon: require("@tt/i18n/icon/pt.png"), areaCode: 55 },
      // { name: "English", value: "en", icon: require("@tt/i18n/icon/en.png"), areaCode: 1 },
      // { name: "Español", value: "es", icon: require("@tt/i18n/icon/es.png"), areaCode: 34 },
      { name: "Tiếng Việt", value: "vi", icon: require("@tt/i18n/icon/vi.png"), areaCode: 1 },
      // { name: "繁體中文", value: "tw", icon: require("@tt/i18n/icon/tw.png"), areaCode: 1 }
    ],
    rechargeList: [
      "20000",
      "500000",
      "1000000",
      "5000000",
      "10000000",
      "30000000"
    ],
    needCode: true,
    url: 'https://gt7201.gtzs.cc/',
    // bankInput: true,
    vipHide: false,
    needFromid: true,
    // bankCardLimit: 11,
    banUserName: false,
    notLoginToRegist: true,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    // pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    // useNewWithdraw: true,
    // addCardForms: ["account", "bank", "card", "ifsc"],
    addCardForms: ["account", "bank", "card"],
    theme: "#ff6400",
    navBg: ["#f53d2c", "#ff6534"],
    navBGToBottom: true,
    noticeIconColor: "#000",
    loginSetLans: false,
    homeShowBalanceAtTop: true,
    homeShowLevels: true,
    hallPhonenumStart: 9,
    mineShowAboutBar: true,
    moneyFormatWithBrazil: true,
    hasGiftRate: true,
    banEditBank: true,
    mineShowWallet: true,
    useSignGift: true,
    hideNotice: true,
    registerHasRead: true,
    levelsReverse: true,
    banDefaultRead: true,
    nullPng: require("@tt/assets/Img/null/T7404.png"),
    messageNullPng: require("@tt/assets/Img/null/T7404_message.png"),
    addressNullPng: require("@tt/assets/Img/null/T7404_address.png"),
    nulltrade: require("@tt/assets/Img/null/T7404_1.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4",
    dateFormat: "dd/MM/yyyy",
    banks: [
      { value: "EMAIL", text: "EMAIL" },
      { value: "PHONE", text: "PHONE" },
      { value: "CPF", text: "CPF" },
      { value: "CNPJ", text: "CNPJ" },
      { value: "RANDOM", text: "RANDOM" },
    ]
  },
  "T7404": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 966,
    lan: 'ar',
    needCode: true,
    url: 'https://sales777.vip/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#ff9300",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
  "T7404_test": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 966,
    lan: 'ar',
    needCode: true,
    url: 'https://gt7404.gtzs.cc/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#ff9300",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
  "T7408": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 48,
    lan: 'en',
    needCode: true,
    url: 'https://amallsettlement.club/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#ff9300",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
  "T7408_test": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 48,
    lan: 'en',
    needCode: true,
    url: 'https://gt7404.gtzs.cc/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#ff9300",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
  "T7409": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 44,
    lan: 'en',
    needCode: true,
    url: 'https://amzn1.top/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#FE8A00",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
  "T7409_test": {
    title: 'Amazonbot',
    moneyType: 'USDT',
    needBank: true,
    areaCode: 44,
    lan: 'en',
    needCode: true,
    url: 'https://gt7404.gtzs.cc/',
    unreadBg: "#febd68",
    unreadColor: "#000",
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    dateFormat: "MM/dd/yyyy HH:mm:ss",
    dateFormatNoTime: "MM/dd/yyyy",
    theme: "#FE8A00",
    noticeIconColor: "#000",
    homeTheme: "#e9600a",
    navBg: "#fff",
    hideLoginFooter: true
  },
}

export default verData[ver];