import { Base64 } from "js-base64";
import Cookies from 'js-cookie';

export const maskMiddle = (input) => {
  const str = String(input);
  const length = str.length;
  if (length <= 6) {
    const start = 1;
    const end = length - 1;
    let maskedStr = str.substring(0, start) + '*'.repeat(4) + str.substring(end);
    return maskedStr;
  }
  const start = 3;
  const end = length - 3;
  let maskedStr = str.substring(0, start) + '*'.repeat(4) + str.substring(end);
  return maskedStr;
};

export const generateRandomString = (length = 32) => {
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  var timestamp = Date.now().toString();
  result += timestamp;

  return result;
}

export const baseEncode = () => {
  const code = Base64.encode(generateRandomString()).substring(0, 32);
  setCookieValue('channel', code);
  return code
}

export const setCookieValue = (key, password, time = 36500) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + Number(time));
  Cookies.set(key, password, { expires: expirationDate });
}

export const getCookieValue = (key) => {
  const encryptedPassword = Cookies.get(key);
  return encryptedPassword;
}